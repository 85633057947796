import React, { useContext, useEffect } from 'react';
import { GetServerSidePropsContext } from 'next';
import { useRouter } from 'next/router';

import {
  BrowserProps,
  MakePageResponseType,
  MakePageV3ResponseType,
  TemplateType,
} from '../../../types';

import { fetchTemplates, getMakePage, getMakePageV3 } from '../../../lib/API';
import NonTemplateSection from '@components/make/NonTemplateSection';
import { CMSDimensionType, MakePageType } from '../../../constants/common';
import TemplateSection from '@components/make/TemplateSection';
import useLazyload from 'src/hooks/useLazyload';
import { AnalyticsUtils } from '@invideoio/web-shared/utils/analytics/AnalyticsUtils';
import { registerAndSendPageViewEvent } from '@invideoio/web-shared/utils/analytics/Register';
import { Referrer } from '@invideoio/web-shared/utils/utils.constant';
import { PageType } from '@invideoio/web-shared/types/Analytics';
import { AmplitudeEvents, sendAmplitudeAnalyticEvent } from '@utils/analytics';
import {
  attachTemplates,
  extractTemplateDimensions,
  extractTemplateIds,
  filterBlogResponseForCards,
} from '@utils/common.utils';
import MakeSEO from '@components/common/SEO/MakeSEO';
import { DispatchContext } from '@store/store';
import {
  baseAppTypeLoaded,
  setEditorParams,
  setRedirectionLink,
} from '@store/actions';
import useTranslation from 'next-translate/useTranslation';
import MakePageContainerV3 from '@layouts/MakePageContainerV3';
import { setCacheControlHeaders } from '@utils/server.utils';

interface MakeProps extends BrowserProps {
  makePageState: MakePageResponseType & MakePageV3ResponseType;
  isMakeV3: boolean;
}

const Make = (props: MakeProps) => {
  const { makePageState, isMakeV3 } = props;
  useLazyload();

  const router = useRouter();
  const dispatch = useContext(DispatchContext);

  const { lang } = useTranslation('make');

  const url_path =
    router.locale === 'en'
      ? router.asPath
      : `/${router.locale}${router.asPath}`;

  useEffect(() => {
    const AppName = process.env.NEXT_PUBLIC_APP_NAME;

    if (AppName === 'kizoa') {
      AnalyticsUtils.setReferrer(Referrer.KIZOA);
    } else {
      AnalyticsUtils.setReferrer(Referrer.WEBSITE);
    }

    registerAndSendPageViewEvent(PageType.MAKE, {
      location: window.location.href,
    });

    sendAmplitudeAnalyticEvent(AmplitudeEvents.appLoad, {
      'Make page Revamp Treatment': 1,
    });

    if (makePageState.heroSection?.CTAUrl) {
      let redirectURL = makePageState.heroSection?.CTAUrl;
      if (!redirectURL.match(/^https?:\/\/(\S+\.)?invideo\.io/)) {
        const host = process.env.NEXT_PUBLIC_BASE_HOST || 'https://invideo.io';
        redirectURL = `${host}${redirectURL}`;
      }
      dispatch(setRedirectionLink(redirectURL));
    }

    if (makePageState.ExtraData) {
      const { searchTerm, tabType, categoryTray } = makePageState.ExtraData;

      dispatch(setEditorParams({ searchTerm, tabType, categoryTray }));
    }

    if (makePageState.BaseAppType) {
      dispatch(baseAppTypeLoaded(makePageState.BaseAppType));
    }
  }, []);

  return (
    <React.Fragment>
      <MakeSEO
        seo={makePageState.SEO}
        url={url_path}
        FAQItems={makePageState.FaqItems}
        HowToSchema={makePageState.HowToSchema}
        locale={lang}
      />
      {!isMakeV3 &&
        makePageState &&
        ((makePageState.pageType === MakePageType.NonTemplate && (
          <NonTemplateSection makePageState={makePageState} />
        )) ||
          (makePageState.pageType === MakePageType.Template && (
            <TemplateSection makePageState={makePageState} />
          )))}
      {isMakeV3 && makePageState && (
        <MakePageContainerV3 makePageState={makePageState} />
      )}
    </React.Fragment>
  );
};

export async function getServerSideProps(ctx: GetServerSidePropsContext) {
  let makePageState = null;
  let isMakeV3 = false;
  try {
    const lang = getLanguage(ctx.locale);
    const slug = getSlug(ctx.query.id, ctx.locale);
    const appName = process.env.NEXT_PUBLIC_APP_NAME;

    if (appName === 'invideo') {
      try {
        makePageState = await getMakePageV3(slug, lang);

        if (makePageState) {
          if (Array.isArray(makePageState.invideo_blogs)) {
            makePageState.invideo_blogs = filterBlogResponseForCards(
              makePageState.invideo_blogs,
            );
          }
          isMakeV3 = true;
        }
      } catch (error) {
        console.log(`Unable to fetch Make V3 post`);
      }
    }

    if (appName === 'invideo' && !makePageState) {
      makePageState = await getMakePage(slug, lang);
    }

    if (
      makePageState &&
      makePageState.templateSlide_1 &&
      makePageState.templateSlide_2 &&
      makePageState.templateSlide_3 &&
      makePageState.templateSlide_4
    ) {
      const ids = extractTemplateIds(
        makePageState.templateSlide_1,
        makePageState.templateSlide_2,
        makePageState.templateSlide_3,
        makePageState.templateSlide_4,
      );
      const dimensions: CMSDimensionType[] = extractTemplateDimensions(
        makePageState.templateSlide_1,
        makePageState.templateSlide_2,
        makePageState.templateSlide_3,
        makePageState.templateSlide_4,
      );
      const templates: TemplateType[] = await fetchTemplates(ids, dimensions);

      if (ids.length !== templates.length) {
        throw `Could not fetch few images from ${ids} got ${templates}`;
      }
      makePageState = {
        ...makePageState,
        ...attachTemplates({
          templateSlide1: makePageState.templateSlide_1,
          templateSlide2: makePageState.templateSlide_2,
          templateSlide3: makePageState.templateSlide_3,
          templateSlide4: makePageState.templateSlide_4,
          images: templates,
        }),
      };
    }
  } catch (error) {
    console.log(error);
    return { notFound: true };
  }

  let maxage = 3600;

  if (process.env.NEXT_PUBLIC_ENV !== 'production') {
    maxage = 300; // 5mins
  }

  setCacheControlHeaders(ctx, maxage);

  return { props: { makePageState, isMakeV3 } };
}

function getLanguage(locale?: string) {
  if (locale === 'jp') {
    return 'ja';
  }

  if (locale === 'kr') {
    return 'ko';
  }

  return locale || 'en';
}

function getSlug(queryId: string | string[] | undefined, locale?: string) {
  if (!locale || locale === 'en') {
    return queryId as string;
  }
  return `${locale}-${queryId}`;
}

export default Make;
